const appBaseUrl = '/app';

const urlRoots = {
  app: appBaseUrl,
  auth: '/',
  accessControl: `${appBaseUrl}/access-control`,
  configuration: `${appBaseUrl}/configuration`,
  license: `${appBaseUrl}/license`,
};

export const urlStructure = {
  ...urlRoots,
  // Auth
  login: '/login',
  register: '/register',
  setPassword: '/set-password',
  forgotPassword: '/forgot-password',
  // Other
  userprofile: `${urlRoots.app}/user-profile`,
  filesForDownload: `${urlRoots.app}/files`,
  orderStatus: '/order-status',
  // Configuration
  configurationUsers: `${urlRoots.configuration}/users`,
  configurationUserCategories: `${urlRoots.configuration}/user-categories`,
  configurationSystemCategories: `${urlRoots.configuration}/system-categories`,
  // Access Control
  accessControlPreview: `${urlRoots.accessControl}/screen-preview`,
  accessControlHistory: `${urlRoots.accessControl}/history`,
  accessControlAppStats: `${urlRoots.accessControl}/app-stats`,
  accessControlWebStats: `${urlRoots.accessControl}/web-stats`,
  accessControlDetailedHistory: `${urlRoots.accessControl}/detailed-history`,
  // License
  planConfiguration: `${urlRoots.license}/plan-configuration`,
  licenseCheckout: `${urlRoots.license}/checkout`,
};
